.pagination-mining {
  display: inline-block;
}

.pagination-mining a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination-mining a.active {
  background-color: #1e7cd4;
  color: white;
  border-radius: 5px;
}

.pagination-mining a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

.Mining_ultimas {
  background-color: #cce5ff !important;
}

.Mining_danger {
  background-color: #f8d7da !important;
}

.Mining_posted {
  background-color: #d4edda !important;
}

.Mining_automatic {
  background-color: #fff3cd !important;
}
