/**
Fixes
*/
.card-header-fix {
  justify-content: space-between;
}

.calendar-custom-outline {
  &:focus {
    outline: 3px solid orange;
  }
}

/**
App Renderer
*/
.calendar-app-renderer {
  width: 100%;

  &,
  ul,
  p {
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-indent: 0;
  }

  > li {
    > p {
      padding: 8px 12px;
      background: #f1f2f6;
      color: #253143;
      border: 1px solid #d1d1d1;
      border-left: 0;
      border-right: 0;

      text-align: center;
    }

    > p::first-letter {
      text-transform: uppercase;
    }

    > ul {
      > li {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        padding: 15px 15px;
        background: #fff;
        color: #3a3a3a;

        & + li {
          border-top: 1px solid #d8d8d8;
        }
      }

      .calendar-app-renderer--first {
        margin-right: 20px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        > time {
          opacity: 0.8;
          margin-bottom: -5px;
        }
      }

      .calendar-app-renderer--second {
        margin-right: 20px;
      }

      .calendar-app-renderer--third {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > p {
          font-size: 16px;
        }
      }
    }
  }
}

.calendar-app--content-renderer {
  display: flex;

  > div {
    > span {
      font-weight: bold;
    }

    > .calendar-app--content-renderer-subfields {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      color: #494b4e;

      > div {
        display: flex;
        align-items: center;

        > .calendar-app--content-renderer-dot {
          display: inline-block;

          width: 2px;
          height: 2px;

          margin: 0 8px;

          background: #494b4e;
          border-radius: 50%;
        }
      }
    }
  }

  .calendar-app--content-renderer-dot {
  }
}

/**
Event
*/
.event-edit-indicators {
  display: flex;
  flex-wrap: wrap;

  > div {
    padding-right: 20px;

    min-width: 230px;
  }
}

/**
Page Styles
*/
.calendar-root {
  .calendar-root-wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    @media screen and (min-width: 700px) {
      flex-direction: row;

      .calendar-wrapper {
        margin-right: 10px;
      }
    }

    .calendar-wrapper {
      width: 100%;
      max-width: 400px;

      /**
      Fix
      */
      .Cal__Header__day {
        font-size: 28px;
      }

      @media screen and (max-width: 450px) {
        .Cal__Header__root {
          min-height: unset;
          padding: 5px 20px 0;
        }
      }
    }
  }

  .calendar-event-filters {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    @media screen and (min-width: 600px) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      > .form-group {
        & + .form-group {
          margin-left: 20px;
        }
      }
    }

    .calendar-event-enable-date {
      background: transparent;
      font-size: 80%;
      font-weight: bold;
      opacity: 0.7;
      border: 0;
      color: rgb(115, 180, 223);
    }

    .calendar-event-datepicker {
      min-width: 200px;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0 0.375rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      color: #5c6873;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #e4e7ea;
      border-radius: 0.25rem;
    }
  }
}

/**
Fields Styles
*/
.subfields {
  margin-top: 5px;

  .subfield-instance {
    display: block;
  }

  .subfield-input,
  .subfield-input-unit,
  .subfield-select {
    max-width: 80px;

    @media screen and (min-width: 410px) {
      max-width: 100px;
    }

    padding: 6px 12px;

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;

    border-radius: 0.25em;
    border: 1px solid #e4e7ea;

    background: rgb(255, 255, 255);
  }

  .subfield-input-unit {
    border-left: 0;
    max-width: 60px;
    margin-right: 10px;

    background: rgb(218, 218, 218);

    border-radius: 0 10px 10px 0;
  }

  .subfield-select {
    max-width: 135px;

    appearance: menulist;
    color: #000;
    background: #ddd;
  }
}

.field-submit--button {
  margin-top: 10px;

  padding: 6px 12px;

  border: 0;
  border-radius: 4px;

  color: #fff;
  background: #20a8d8;

  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

.submitted-subfields {
  margin-top: 10px;

  .subfield-row {
    & + .subfield-row {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid rgb(116, 116, 116);
    }

    max-width: 600px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .subfield-name {
      margin-right: 20px;

      font-weight: bold;
      font-size: 15px;
    }

    button {
      margin-left: 5px;

      text-align: center;

      font-size: 13px;
      padding: 0;

      border: 0;
      border-radius: 5px;

      width: 45px;

      color: #fff;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

      &.subfield-edit {
        background: #20a8d8;
      }

      &.subfield-delete {
        background: #ff2b3d;
      }
    }
  }
}

/**
Scratch
*/

.scratch-event-row {
  & + div {
    margin-top: 10px;
    padding-top: 10px;

    border-top: 1px solid rgb(148, 148, 148);
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  input,
  select {
    background: #fff;
    min-height: 27px;

    margin-right: 5px;
  }

  select {
    border-radius: 4px;
  }

  input.scratch-input {
    flex: 1;
    min-width: 20px;
    margin-left: 20px;
    padding: 5px;
    color: rgb(92, 104, 115);
    border: 1px solid rgb(228, 231, 234);
    border-radius: 4px;
  }

  .scratch-checkbox:focus {
    outline: 2px solid orange;
  }
}

/**
Calendar Component
*/
.marked-date {
  background: rgb(2, 19, 13);
}

/**
React Tickers
*/
.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #e4e7ea;
  border-radius: 4px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #e4e7ea;
  border-radius: 2px;
  background: #f1f1f1;
  // margin-top: 4px;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding-top: 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

// styles of component DateFilterEvents
.Modal-DateFilterEvents {
  margin-top: 10px;
  position: absolute;
  z-index: 9999;
}
