@font-face {
  font-family: 'tcnews';

  src: url('./fonts/tcnews.eot?61361320');
  src: url('./fonts/tcnews.eot?61361320#iefix') format('embedded-opentype'),
    url('./fonts/tcnews.woff2?61361320') format('woff2'),
    url('./fonts/tcnews.woff?61361320') format('woff'),
    url('./fonts/tcnews.ttf?61361320') format('truetype'),
    url('./fonts/tcnews.svg?61361320#tcnews') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'tcnews';
      src: url('../font/tcnews.svg?61361320#tcnews') format('svg');
    }
  }
  */

[class^='TCicon-']:before,
[class*=' TCicon-']:before {
  font-family: 'tcnews';
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.TCicon-player:before {
  content: '\e800';
} /* '' */
.TCicon-player-block:before {
  content: '\e801';
} /* '' */
.TCicon-radar:before {
  content: '\e802';
} /* '' */
.TCicon-reload:before {
  content: '\e803';
} /* '' */
.TCicon-right:before {
  content: '\e804';
} /* '' */
.TCicon-search:before {
  content: '\e805';
} /* '' */
.TCicon-set-down:before {
  content: '\e806';
} /* '' */
.TCicon-share:before {
  content: '\e807';
} /* '' */
.TCicon-sign-out:before {
  content: '\e808';
} /* '' */
.TCicon-small-important:before {
  content: '\e809';
} /* '' */
.TCicon-small-sound:before {
  content: '\e80a';
} /* '' */
.TCicon-social-facebook:before {
  content: '\e80b';
} /* '' */
.TCicon-social-set:before {
  content: '\e80c';
} /* '' */
.TCicon-social-twitter:before {
  content: '\e80d';
} /* '' */
.TCicon-social-whatsapp:before {
  content: '\e80e';
} /* '' */
.TCicon-social-youtube:before {
  content: '\e80f';
} /* '' */
.TCicon-sort-amount-down:before {
  content: '\e810';
} /* '' */
.TCicon-star:before {
  content: '\e811';
} /* '' */
.TCicon-star-solid-dark:before {
  content: '\e812';
} /* '' */
.TCicon-star-white:before {
  content: '\e813';
} /* '' */
.TCicon-tc-icons-arrow:before {
  content: '\e814';
} /* '' */
.TCicon-tc-icons-back:before {
  content: '\e815';
} /* '' */
.TCicon-tc-icons-blog:before {
  content: '\e816';
} /* '' */
.TCicon-tc-icons-calculator:before {
  content: '\e817';
} /* '' */
.TCicon-tc-icons-calendar:before {
  content: '\e818';
} /* '' */
.TCicon-tc-icons-check:before {
  content: '\e819';
} /* '' */
.TCicon-tc-icons-clessidre:before {
  content: '\e81a';
} /* '' */
.TCicon-tc-icons-close:before {
  content: '\e81b';
} /* '' */
.TCicon-tc-icons-comment:before {
  content: '\e81c';
} /* '' */
.TCicon-tc-icons-company:before {
  content: '\e81d';
} /* '' */
.TCicon-tc-icons-compare-proposals:before {
  content: '\e81e';
} /* '' */
.TCicon-tc-icons-document:before {
  content: '\e81f';
} /* '' */
.TCicon-tc-icons-download:before {
  content: '\e820';
} /* '' */
.TCicon-tc-icons-edit:before {
  content: '\e821';
} /* '' */
.TCicon-tc-icons-equity:before {
  content: '\e822';
} /* '' */
.TCicon-tc-icons-estate:before {
  content: '\e823';
} /* '' */
.TCicon-tc-icons-filters:before {
  content: '\e824';
} /* '' */
.TCicon-tc-icons-financing:before {
  content: '\e825';
} /* '' */
.TCicon-tc-icons-hide:before {
  content: '\e826';
} /* '' */
.TCicon-tc-icons-home:before {
  content: '\e827';
} /* '' */
.TCicon-tc-icons-lock:before {
  content: '\e828';
} /* '' */
.TCicon-tc-icons-locked:before {
  content: '\e829';
} /* '' */
.TCicon-tc-icons-log-out:before {
  content: '\e82a';
} /* '' */
.TCicon-tc-icons-maximize:before {
  content: '\e82b';
} /* '' */
.TCicon-tc-icons-menu:before {
  content: '\e82c';
} /* '' */
.TCicon-tc-icons-more:before {
  content: '\e82d';
} /* '' */
.TCicon-tc-icons-notification:before {
  content: '\e82e';
} /* '' */
.TCicon-tc-icons-pen:before {
  content: '\e82f';
} /* '' */
.TCicon-tc-icons-plus:before {
  content: '\e830';
} /* '' */
.TCicon-tc-icons-proposal-details:before {
  content: '\e831';
} /* '' */
.TCicon-tc-icons-revert:before {
  content: '\e832';
} /* '' */
.TCicon-tc-icons-search:before {
  content: '\e833';
} /* '' */
.TCicon-tc-icons-settings:before {
  content: '\e834';
} /* '' */
.TCicon-tc-icons-share:before {
  content: '\e835';
} /* '' */
.TCicon-tc-icons-show:before {
  content: '\e836';
} /* '' */
.TCicon-tc-icons-tools:before {
  content: '\e837';
} /* '' */
.TCicon-tc-icons-trade-ideas:before {
  content: '\e838';
} /* '' */
.TCicon-tc-icons-trash:before {
  content: '\e839';
} /* '' */
.TCicon-tc-icons-user:before {
  content: '\e83a';
} /* '' */
.TCicon-tc-icons-wealth:before {
  content: '\e83b';
} /* '' */
.TCicon-tcnews:before {
  content: '\e83c';
} /* '' */
.TCicon-tc-small-arrow:before {
  content: '\e83d';
} /* '' */
.TCicon-tc-small-back:before {
  content: '\e83e';
} /* '' */
.TCicon-tc-small-check:before {
  content: '\e83f';
} /* '' */
.TCicon-tc-small-clessidre:before {
  content: '\e840';
} /* '' */
.TCicon-tc-small-clock:before {
  content: '\e841';
} /* '' */
.TCicon-tc-small-close:before {
  content: '\e842';
} /* '' */
.TCicon-tc-small-comment:before {
  content: '\e843';
} /* '' */
.TCicon-tc-small-copy:before {
  content: '\e844';
} /* '' */
.TCicon-tc-small-document:before {
  content: '\e845';
} /* '' */
.TCicon-tc-small-download:before {
  content: '\e846';
} /* '' */
.TCicon-tc-small-info:before {
  content: '\e847';
} /* '' */
.TCicon-tc-small-lock:before {
  content: '\e848';
} /* '' */
.TCicon-tc-small-plus:before {
  content: '\e849';
} /* '' */
.TCicon-tc-small-revert:before {
  content: '\e84a';
} /* '' */
.TCicon-tc-small-settings:before {
  content: '\e84b';
} /* '' */
.TCicon-tc-text-editor-attach:before {
  content: '\e84c';
} /* '' */
.TCicon-tc-text-editor-bold:before {
  content: '\e84d';
} /* '' */
.TCicon-tc-text-editor-center-align:before {
  content: '\e84e';
} /* '' */
.TCicon-tc-text-editor-image:before {
  content: '\e84f';
} /* '' */
.TCicon-tc-text-editor-indent-left:before {
  content: '\e850';
} /* '' */
.TCicon-tc-text-editor-indent-right:before {
  content: '\e851';
} /* '' */
.TCicon-tc-text-editor-italic:before {
  content: '\e852';
} /* '' */
.TCicon-tc-text-editor-left-align:before {
  content: '\e853';
} /* '' */
.TCicon-tc-text-editor-link:before {
  content: '\e854';
} /* '' */
.TCicon-tc-text-editor-list-01:before {
  content: '\e855';
} /* '' */
.TCicon-tc-text-editor-list-02:before {
  content: '\e856';
} /* '' */
.TCicon-tc-text-editor-quote:before {
  content: '\e857';
} /* '' */
.TCicon-tc-text-editor-right-align:before {
  content: '\e858';
} /* '' */
.TCicon-tc-text-editor-strikethrough:before {
  content: '\e859';
} /* '' */
.TCicon-tc-text-editor-underline:before {
  content: '\e85a';
} /* '' */
.TCicon-terminal:before {
  content: '\e85b';
} /* '' */
.TCicon-terminal-cotacoes:before {
  content: '\e85c';
} /* '' */
.TCicon-terminal-cursos:before {
  content: '\e85d';
} /* '' */
.TCicon-terminal-eventos:before {
  content: '\e85e';
} /* '' */
.TCicon-terminal-loja:before {
  content: '\e85f';
} /* '' */
.TCicon-terminal-master:before {
  content: '\e860';
} /* '' */
.TCicon-terminal-plantao:before {
  content: '\e861';
} /* '' */
.TCicon-terminal-podcasts:before {
  content: '\e862';
} /* '' */
.TCicon-terminal-relatorio:before {
  content: '\e863';
} /* '' */
.TCicon-terminal-trad3:before {
  content: '\e864';
} /* '' */
.TCicon-terminal-trade-ideas:before {
  content: '\e865';
} /* '' */
.TCicon-terminal-videos:before {
  content: '\e866';
} /* '' */
.TCicon-termina-tcschool:before {
  content: '\e867';
} /* '' */
.TCicon-trad3:before {
  content: '\e868';
} /* '' */
.TCicon-trash:before {
  content: '\e869';
} /* '' */
.TCicon-trophy:before {
  content: '\e86a';
} /* '' */
.TCicon-up:before {
  content: '\e86b';
} /* '' */
.TCicon-user-add:before {
  content: '\e86c';
} /* '' */
.TCicon-zoom:before {
  content: '\e86d';
} /* '' */
.TCicon-zoom-out:before {
  content: '\e86e';
} /* '' */
.TCicon-add:before {
  content: '\e86f';
} /* '' */
.TCicon-advance:before {
  content: '\e870';
} /* '' */
.TCicon-arroba:before {
  content: '\e871';
} /* '' */
.TCicon-b:before {
  content: '\e872';
} /* '' */
.TCicon-bag:before {
  content: '\e873';
} /* '' */
.TCicon-bag-money:before {
  content: '\e874';
} /* '' */
.TCicon-bag-money-black:before {
  content: '\e875';
} /* '' */
.TCicon-bell:before {
  content: '\e876';
} /* '' */
.TCicon-bell-add:before {
  content: '\e877';
} /* '' */
.TCicon-bell-block:before {
  content: '\e878';
} /* '' */
.TCicon-block:before {
  content: '\e879';
} /* '' */
.TCicon-board:before {
  content: '\e87a';
} /* '' */
.TCicon-box:before {
  content: '\e87b';
} /* '' */
.TCicon-cache:before {
  content: '\e87c';
} /* '' */
.TCicon-cadeado:before {
  content: '\e87d';
} /* '' */
.TCicon-calendar:before {
  content: '\e87e';
} /* '' */
.TCicon-calendar-add:before {
  content: '\e87f';
} /* '' */
.TCicon-cart:before {
  content: '\e880';
} /* '' */
.TCicon-cellphone:before {
  content: '\e881';
} /* '' */
.TCicon-chart-board:before {
  content: '\e882';
} /* '' */
.TCicon-chart-line:before {
  content: '\e883';
} /* '' */
.TCicon-chat:before {
  content: '\e884';
} /* '' */
.TCicon-circle-down:before {
  content: '\e885';
} /* '' */
.TCicon-circle-more:before {
  content: '\e886';
} /* '' */
.TCicon-clipboard-chart:before {
  content: '\e887';
} /* '' */
.TCicon-clips:before {
  content: '\e888';
} /* '' */
.TCicon-clock:before {
  content: '\e889';
} /* '' */
.TCicon-close:before {
  content: '\e88a';
} /* '' */
.TCicon-comment:before {
  content: '\e88b';
} /* '' */
.TCicon-comments:before {
  content: '\e88c';
} /* '' */
.TCicon-comment-star:before {
  content: '\e88d';
} /* '' */
.TCicon-compass:before {
  content: '\e88e';
} /* '' */
.TCicon-config:before {
  content: '\e88f';
} /* '' */
.TCicon-console:before {
  content: '\e890';
} /* '' */
.TCicon-coupom:before {
  content: '\e891';
} /* '' */
.TCicon-down:before {
  content: '\e892';
} /* '' */
.TCicon-emoji-airplane:before {
  content: '\e893';
} /* '' */
.TCicon-emoji-ball:before {
  content: '\e894';
} /* '' */
.TCicon-emoji-clock:before {
  content: '\e895';
} /* '' */
.TCicon-emoji-face:before {
  content: '\e896';
} /* '' */
.TCicon-emoji-flag:before {
  content: '\e897';
} /* '' */
.TCicon-emoji-folha:before {
  content: '\e898';
} /* '' */
.TCicon-emoji-hamburger:before {
  content: '\e899';
} /* '' */
.TCicon-emoji-hearth:before {
  content: '\e89a';
} /* '' */
.TCicon-emoji-lamp:before {
  content: '\e89b';
} /* '' */
.TCicon-emoticon-add:before {
  content: '\e89c';
} /* '' */
.TCicon-external-link:before {
  content: '\e89d';
} /* '' */
.TCicon-file-people:before {
  content: '\e89e';
} /* '' */
.TCicon-folder:before {
  content: '\e89f';
} /* '' */
.TCicon-funnel:before {
  content: '\e8a0';
} /* '' */
.TCicon-grid:before {
  content: '\e8a1';
} /* '' */
.TCicon-hand:before {
  content: '\e8a2';
} /* '' */
.TCicon-headphone:before {
  content: '\e8a3';
} /* '' */
.TCicon-heart:before {
  content: '\e8a4';
} /* '' */
.TCicon-home:before {
  content: '\e8a5';
} /* '' */
.TCicon-ic-pause:before {
  content: '\e8a6';
} /* '' */
.TCicon-ic-play:before {
  content: '\e8a7';
} /* '' */
.TCicon-image:before {
  content: '\e8a8';
} /* '' */
.TCicon-lamp:before {
  content: '\e8a9';
} /* '' */
.TCicon-left:before {
  content: '\e8aa';
} /* '' */
.TCicon-link:before {
  content: '\e8ab';
} /* '' */
.TCicon-list:before {
  content: '\e8ac';
} /* '' */
.TCicon-marker:before {
  content: '\e8ad';
} /* '' */
.TCicon-menu:before {
  content: '\e8ae';
} /* '' */
.TCicon-microphone:before {
  content: '\e8af';
} /* '' */
.TCicon-moderador-solid-dark:before {
  content: '\e8b0';
} /* '' */
.TCicon-money:before {
  content: '\e8b1';
} /* '' */
.TCicon-money-circle:before {
  content: '\e8b2';
} /* '' */
.TCicon-more-options:before {
  content: '\e8b3';
} /* '' */
.TCicon-ok:before {
  content: '\e8b4';
} /* '' */
.TCicon-paper-plane:before {
  content: '\e8b5';
} /* '' */
.TCicon-photo:before {
  content: '\e8b6';
} /* '' */
.TCicon-plantao:before {
  content: '\e8b7';
} /* '' */
.TCicon-play:before {
  content: '\e8b8';
} /* '' */
.TCicon-parcerias:before {
  content: '\e8b9';
} /* '' */
