// Here you can add other styles
.nav-tabs .nav-link.active {
  border-bottom: 1px solid #fff;
  margin-bottom: -1px;
}

.badge-lg {
  font-size: 100% !important;
}

.sidebar .nav-link {
  display: block;
  padding: 0.75rem;
  color: #fff;
  text-decoration: none;
  background: transparent;
}

body {
  background-color: #e5e5e6;
}
