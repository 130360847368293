.attach-box {
  display: flex;
  border: 1px solid #e0e2e7;
  background: #fff; // height: 125px;
  max-width: 800px;
  border-radius: 10px;
  height: 100px;
  margin-top: 10px;
  .attach-image {
    overflow: hidden;
    flex-direction: column;
    img {
      max-width: 300px;
      max-height: auto;
      height: 100px;
      width: auto;
      max-height: 100px;
      border-radius: 0 !important;
      border-bottom-left-radius: 10px !important;
      border-top-left-radius: 10px !important;
    }
  }
  .attach-title-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 10px;
    .source {
      font-size: 10px;
    }
    .title {
      font-size: 12px;
    }
    .link {
      max-width: 100%;
      width: 594px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 5px 0 5px 0px;
      a {
        font-weight: 500;
        margin: 5px 0;
        font-size: 14px;
      }
    }
  }
}

.modal.fade {
  display: flex !important;

  justify-content: center;
  align-items: center;
}
