/*
style fixes
*/
.card_center {
  display: flex;
  justify-content: center;
}

/* Tooltip container */
.tooltipButton {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltipButton .tooltipText {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltipButton .tooltipText::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipButton:hover .tooltipText {
  visibility: visible;
  opacity: 1;

  @media screen and (max-width: 420px) {
    visibility: hidden;
  }
}

.preview_laptop_container {
  flex-direction: row;
  width: 60%;
  height: 700px;
  border: 10px solid #000;
  border-radius: 10px;
  margin-left: 15px;
  margin-right: 15px;

  @media screen and (max-width: 420px) {
    width: 95%;
  }
}

.preview_laptop_header {
  display: block;
  // max-height: 400px;
  margin-bottom: 50px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
}

.preview_background_Header {
  max-height: 400px;
  margin-bottom: 50px;
  background: linear-gradient(0deg, rgb(14, 21, 29) 0%, rgba(255, 255, 255, 0) 70%);
}

.peview_dataType {
  align-items: center;
  height: 80px;
  padding-left: 74px;
  padding-top: 18px;
  border-bottom: 1px solid rgba(207, 206, 206, 0.3);

  svg {
    height: 25px;
    margin-top: -3px;
    margin-right: 10px;
    fill: white;
  }

  span {
    color: #fff;
    font-size: 20px;
  }
}

.edition_Expresso {
  padding-top: 22%;
  padding-bottom: 10px;
  padding-left: 74px;
  font-weight: 600;
  color: rgba(207, 206, 206, 0.9);
}

.title_Expresso {
  font-size: 20px;
  color: white;
  height: 100%;
  width: 90%;
  padding-left: 74px;
}

.Preview_tickers_schedule {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding-right: 30px;

  // svg {
  //   height: 20px;
  //   // margin-top: 3p;
  //   margin-right: 5px;
  //   fill: #fff;
  // }
}

.icon_container {
  border-bottom: 1px solid rgba(207, 206, 206, 0.6);
  padding-bottom: 12px;
}

.item_Ticker {
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
  border-bottom: 1px solid rgba(207, 206, 206, 0.6);
}

.preview_headerSchedule {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  font-size: 16px;
  border-bottom: 1px solid rgba(207, 206, 206, 0.6);
}

.preview_itemSchedule {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.preview_phone_container {
  margin-left: 15px;
  margin-right: 15px;
  width: 320px;
  height: 700px;
  border: 3px solid rgb(78, 91, 106);
  border-radius: 50px;
}
.preview_phone_border {
  display: flex;
  overflow: hidden;
  border: 10px solid;
  border-radius: 47px;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  flex-direction: column;
}

.preview_phome_header {
  background-repeat: no-repeat !important;
  background-size: auto 100% !important;
  background-position: top !important;
  height: 60%;

  svg {
    height: 25px;
    margin-left: 15px;
    margin-right: 10px;
    fill: white;
  }

  span {
    color: #fff;
  }
}

.preview_phome_background {
  height: 100%;
  background: linear-gradient(0deg, rgb(14, 21, 29) 0%, rgba(255, 255, 255, 0) 70%);
}

.preview_phone_edition {
  font-weight: 600;
  margin-top: 57%;
  margin-bottom: 10px;
  padding-left: 15px;
  font-size: 13px;
}

.preview_phone_title {
  font-size: 18px;
  height: 100%;
  width: 90%;
  padding-left: 15px;
}
.item_Ticker_phone {
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
  border-bottom: 1px solid rgba(207, 206, 206, 0.6);
}

.item_ticker_padrao {
  display: flex;
  font-size: 12px;
  align-items: center;
}

.containerMain {
  position: relative;
  display: flex;
  flex-direction: column;
  // min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #c8ced3;
  border-radius: 0.25rem;

  margin: 0 auto;
  width: 100%;
}

.FormEspresso {
  display: flex;

  .ImageEspresso {
    width: 120px;
  }
  @media screen and (max-width: 420px) {
    flex-direction: column;
    .ImageEspresso {
      width: 70px;
      padding-left: 10px;
    }
  }
}

.ListTickersEspresso {
  min-width: 23%;
  margin-left: 10px;
  margin-right: 10px;

  @media screen and (max-width: 420px) {
    margin-top: 70px;
    margin-bottom: 20px;
  }
}

.fileInputImageEspresso {
  width: 25%;
  @media screen and (max-width: 420px) {
    display: flex;
  }
}
